import React, { useState } from 'react';
import { isEmail } from 'validator';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import InputMask from 'react-input-mask';

import {
  useMediaQuery,
  Button,
  Checkbox,
  TextField,
  Radio,
  RadioGroup,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
} from '@mui/material';
import Layout from '../../components/Bug/Layout';
import Loading from '../../components/Loading';
import * as actions from '../../store/modules/auth/actions';
import { StyledContainer, Container } from './styled';

export default function Register() {
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.auth.isLoading);
  const id = useSelector((state) => state.auth.user.id);
  const history = useHistory();

  const [formStep, setFormStep] = useState(1);
  const [formData, setFormData] = useState({
    documentoTipo: 'CPF',
    nome: '',
    documento: '',
    telefone: '',
    email: '',
    inscricao: '',
    cep: '',
    cidade: '',
    logradouro: '',
    numero: '',
    UF: '',
    bairro: '',
    complemento: '',
    emissao: false,
  });
  const [formErrors, setFormErrors] = useState({
    nome: false,
    email: false,
  });

  const handleChange = (e) => {
    const { name, value, checked, type } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const handleNextStep = () => {
    setFormStep(formStep + 1);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const newFormErrors = {};

    if (!formData.nome || formData.nome.length < 3 || formData.nome.length > 255) {
      newFormErrors.nome = true;
    } else {
      newFormErrors.nome = false;
    }

    if (!formData.email || !isEmail(formData.email)) {
      newFormErrors.email = true;
    } else {
      newFormErrors.email = false;
    }

    setFormErrors(newFormErrors);

    if (Object.values(newFormErrors).some((error) => error)) {
      return;
    }

    dispatch(
      actions.registerClientRequest({
        ...formData,
        history,
        userId: id,
      }),
    );
  };

  const getMask = () => {
    return formData.documentoTipo === 'CPF' ? '999.999.999-99' : '99.999.999/9999-99';
  };

  const isSmallScreen = useMediaQuery('(max-width: 600px)');
  const isFormValid = () => {
    // Lista de campos obrigatórios
    const requiredFields = [
      'nome',
      'telefone',
      'email',
      'documento',
      'inscricao',
      'cep',
      'cidade',
      'logradouro',
      'numero',
      'UF',
      'bairro',
    ];
    // Verifica se todos os campos obrigatórios possuem algum valor
    return requiredFields.every((field) => formData[field] !== '');
  };

  return (
    <Layout>
      <Container className={isSmallScreen ? 'small-screen' : ''}>
        <Loading isLoading={isLoading} />
        <StyledContainer>
          <form onSubmit={handleSubmit}>
            <h1>Cadastre um novo cliente</h1>
            {formStep === 1 && (
              <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                  <TextField
                    className="text-field"
                    InputProps={{ disableUnderline: true }}
                    label="Nome *"
                    name="nome"
                    value={formData.nome}
                    onChange={handleChange}
                    placeholder="Nome"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    error={formErrors.nome}
                    style={{ boxShadow: 'none' }}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField
                    className="text-field"
                    InputProps={{
                      disableUnderline: true,
                      inputComponent: InputMask,
                      inputProps: {
                        mask: '(99) 99999 9999',
                        value: formData.telefone,
                        onChange: handleChange,
                        maskChar: ' ',
                        name: 'telefone',
                      },
                    }}
                    label="Celular *"
                    name="telefone"
                    value={formData.telefone}
                    onChange={handleChange}
                    placeholder="Seu Celular"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    style={{ boxShadow: 'none' }}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField
                    className="text-field"
                    InputProps={{ disableUnderline: true }}
                    label="E-mail *"
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    placeholder="Seu e-mail"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    error={formErrors.email}
                    style={{ boxShadow: 'none' }}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <FormControl component="fieldset">
                    <FormLabel component="legend">
                      <p>Tipo de Documento</p>
                    </FormLabel>
                    <RadioGroup
                      className="RadioGroup"
                      row
                      aria-label="documentoTipo"
                      name="documentoTipo"
                      value={formData.documentoTipo}
                      onChange={handleChange}
                    >
                      <FormControlLabel value="CPF" control={<Radio />} label="CPF" />
                      <FormControlLabel value="CNPJ" control={<Radio />} label="CNPJ" />
                    </RadioGroup>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={4}>
                  <InputMask mask={getMask()} value={formData.documento} onChange={handleChange} name="documento">
                    {() => (
                      <TextField
                        className="text-field"
                        InputProps={{ disableUnderline: true }}
                        label={formData.documentoTipo}
                        name="documento"
                        value={formData.documento}
                        onChange={handleChange}
                        placeholder={`Seu ${formData.documentoTipo}`}
                        fullWidth
                        margin="normal"
                        variant="outlined"
                        style={{ boxShadow: 'none' }}
                      />
                    )}
                  </InputMask>
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField
                    className="text-field"
                    InputProps={{ disableUnderline: true }}
                    label="Inscrição Estadual *"
                    name="inscricao"
                    value={formData.inscricao}
                    onChange={handleChange}
                    placeholder="Inscrição Estadual"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    style={{ boxShadow: 'none' }}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField
                    className="text-field"
                    InputProps={{ disableUnderline: true }}
                    label="CEP *"
                    name="cep"
                    value={formData.cep}
                    onChange={handleChange}
                    placeholder="CEP"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    style={{ boxShadow: 'none' }}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField
                    className="text-field"
                    InputProps={{ disableUnderline: true }}
                    label="Cidade *"
                    name="cidade"
                    value={formData.cidade}
                    onChange={handleChange}
                    placeholder="Cidade"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    style={{ boxShadow: 'none' }}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField
                    className="text-field"
                    InputProps={{ disableUnderline: true }}
                    label="Logradouro *"
                    name="logradouro"
                    value={formData.logradouro}
                    onChange={handleChange}
                    placeholder="Logradouro"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    style={{ boxShadow: 'none' }}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField
                    className="text-field"
                    InputProps={{ disableUnderline: true }}
                    label="Número *"
                    name="numero"
                    value={formData.numero}
                    onChange={handleChange}
                    placeholder="Número"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    style={{ boxShadow: 'none' }}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField
                    className="text-field"
                    InputProps={{ disableUnderline: true }}
                    label="UF *"
                    name="UF"
                    value={formData.UF}
                    onChange={handleChange}
                    placeholder="UF"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    style={{ boxShadow: 'none' }}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField
                    className="text-field"
                    InputProps={{ disableUnderline: true }}
                    label="Bairro *"
                    name="bairro"
                    value={formData.bairro}
                    onChange={handleChange}
                    placeholder="Bairro"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    style={{ boxShadow: 'none' }}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField
                    className="text-field"
                    InputProps={{ disableUnderline: true }}
                    label="Complemento *"
                    name="complemento"
                    value={formData.complemento}
                    onChange={handleChange}
                    placeholder="Complemento"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    style={{ boxShadow: 'none' }}
                  />
                </Grid>
              </Grid>
            )}
            {formStep === 2 && (
              <div className="form-step">
                <h4>Revisar Informações</h4>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={4}>
                    <TextField
                      className="text-field"
                      InputProps={{ disableUnderline: true }}
                      label="Nome"
                      name="nome"
                      value={formData.nome}
                      placeholder="Nome"
                      fullWidth
                      margin="normal"
                      variant="outlined"
                      style={{ boxShadow: 'none' }}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <TextField
                      className="text-field"
                      InputProps={{
                        disableUnderline: true,
                        inputComponent: InputMask,
                        inputProps: {
                          mask: '(99) 99999 9999',
                          value: formData.telefone,
                          maskChar: ' ',
                        },
                      }}
                      label="Celular"
                      name="telefone"
                      value={formData.telefone}
                      placeholder="Seu Celular"
                      fullWidth
                      margin="normal"
                      variant="outlined"
                      style={{ boxShadow: 'none' }}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <TextField
                      className="text-field"
                      InputProps={{ disableUnderline: true }}
                      label="E-mail"
                      type="email"
                      name="email"
                      value={formData.email}
                      placeholder="Seu e-mail"
                      fullWidth
                      margin="normal"
                      variant="outlined"
                      style={{ boxShadow: 'none' }}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <TextField
                      className="text-field"
                      InputProps={{ disableUnderline: true }}
                      label={formData.documentoTipo}
                      name="documento"
                      value={formData.documento}
                      placeholder={`Seu ${formData.documentoTipo}`}
                      fullWidth
                      margin="normal"
                      variant="outlined"
                      style={{ boxShadow: 'none' }}
                      disabled
                      sx={{
                        width: isSmallScreen ? '80%' : '50%',
                        height: '20px',
                        marginBottom: '10px',
                        '& .MuiOutlinedInput-root': {
                          height: '40px',
                          '&.Mui-focused fieldset': {
                            borderColor: 'blue',
                          },
                          borderRadius: '35px',
                        },
                        '& label.Mui-focused': {
                          color: 'blue',
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <TextField
                      className="text-field"
                      InputProps={{ disableUnderline: true }}
                      label="Inscrição Estadual"
                      name="inscricao"
                      value={formData.inscricao}
                      placeholder="Inscrição Estadual"
                      fullWidth
                      margin="normal"
                      variant="outlined"
                      style={{ boxShadow: 'none' }}
                      disabled
                      sx={{
                        width: isSmallScreen ? '80%' : '50%',
                        height: '20px',
                        marginBottom: '10px',
                        '& .MuiOutlinedInput-root': {
                          height: '40px',
                          '&.Mui-focused fieldset': {
                            borderColor: 'blue',
                          },
                          borderRadius: '35px',
                        },
                        '& label.Mui-focused': {
                          color: 'blue',
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <TextField
                      className="text-field"
                      InputProps={{ disableUnderline: true }}
                      label="CEP"
                      name="cep"
                      value={formData.cep}
                      placeholder="CEP"
                      fullWidth
                      margin="normal"
                      variant="outlined"
                      style={{ boxShadow: 'none' }}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <TextField
                      className="text-field"
                      InputProps={{ disableUnderline: true }}
                      label="Cidade"
                      name="cidade"
                      value={formData.cidade}
                      placeholder="Cidade"
                      fullWidth
                      margin="normal"
                      variant="outlined"
                      style={{ boxShadow: 'none' }}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <TextField
                      className="text-field"
                      InputProps={{ disableUnderline: true }}
                      label="Logradouro"
                      name="logradouro"
                      value={formData.logradouro}
                      placeholder="Logradouro"
                      fullWidth
                      margin="normal"
                      variant="outlined"
                      style={{ boxShadow: 'none' }}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <TextField
                      className="text-field"
                      InputProps={{ disableUnderline: true }}
                      label="Número"
                      name="numero"
                      value={formData.numero}
                      placeholder="Número"
                      fullWidth
                      margin="normal"
                      variant="outlined"
                      style={{ boxShadow: 'none' }}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <TextField
                      className="text-field"
                      InputProps={{ disableUnderline: true }}
                      label="UF"
                      name="UF"
                      value={formData.UF}
                      placeholder="UF"
                      fullWidth
                      margin="normal"
                      variant="outlined"
                      style={{ boxShadow: 'none' }}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <TextField
                      className="text-field"
                      InputProps={{ disableUnderline: true }}
                      label="Bairro"
                      name="bairro"
                      value={formData.bairro}
                      placeholder="Bairro"
                      fullWidth
                      margin="normal"
                      variant="outlined"
                      style={{ boxShadow: 'none' }}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <TextField
                      className="text-field"
                      InputProps={{ disableUnderline: true }}
                      label="Complemento"
                      name="complemento"
                      value={formData.complemento}
                      placeholder="Complemento"
                      fullWidth
                      margin="normal"
                      variant="outlined"
                      style={{ boxShadow: 'none' }}
                      disabled
                    />
                  </Grid>
                </Grid>
                <FormControlLabel
                  className="FormControlLabel"
                  control={
                    <Checkbox name="emissao" checked={formData.emissao} onChange={handleChange} color="primary" />
                  }
                  label="Deseja habilitar a emissão de nota fiscal para este cliente?"
                />
              </div>
            )}
            <div className="form-navigation">
              <Grid item xs={12} md={4}>
                <Button variant="contained" color="primary" onClick={() => history.push('/client')}>
                  Voltar
                </Button>
              </Grid>
              {formStep < 2 && (
                <Grid item xs={12} md={4}>
                  <Button variant="contained" color="primary" onClick={handleNextStep} disabled={!isFormValid()}>
                    Próximo
                  </Button>
                </Grid>
              )}
              {formStep === 2 && (
                <Grid item xs={12} md={4}>
                  <Button type="submit" variant="contained" color="primary">
                    Criar Conta
                  </Button>
                </Grid>
              )}
            </div>
          </form>
        </StyledContainer>
      </Container>
    </Layout>
  );
}
