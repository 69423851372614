import styled from 'styled-components';

export const TextContent = styled.div`
  display: flex;
  flex-direction: ${(props) =>
    props.isSmallScreen
      ? 'column'
      : 'row'}; /* Alinha os cartões em coluna em telas pequenas e em linha em telas maiores */
  justify-content: center; /* Centraliza os cartões horizontalmente */
  align-items: center; /* Alinha os cartões verticalmente */
  margin-top: ${(props) =>
    props.isSmallScreen ? '20px' : '0'}; /* Ajusta a margem superior conforme o tamanho da tela */

  @media (max-width: 1024px) {
    flex-direction: column;
  }
  .card3,
  .card1,
  .card2 {
    color: black;
    background-color: white;
    display: flex;
    width: ${(props) => (props.isSmallScreen ? '40vw' : '30vh')}; /* Ajusta a largura conforme o tamanho da tela */
    height: ${(props) => (props.isSmallScreen ? '20vh' : '30vh')}; /* Ajusta a altura conforme o tamanho da tela */
    align-items: center; /* Centraliza o conteúdo dentro do cartão */
    justify-content: center; /* Centraliza o conteúdo dentro do cartão */
    flex-direction: column;
    border-radius: 20px;
    padding: 10px 20px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
    margin: ${(props) => (props.isSmallScreen ? '10px 0' : '0 10px')}; /* Adiciona espaço entre os cartões */
    &:hover {
      transition: all 0.3s ease;
      transform: scale(1.05);
      color: #0a798d;
      border-radius: 20px;
      box-shadow: 1px 1px 7px #0a798d, 1px 2px 8px #0a798d;
    }

    @media (max-width: 1024px) {
      width: 80vw;
      height: 25vh;
      margin: 10px 0;
    }

    @media (max-width: 768px) {
      width: 50vw;
      height: 20vh;
    }
  }

  p {
    font-size: 20px;
    margin-top: ${(props) =>
      props.isSmallScreen ? '10px' : '0'}; /* Ajusta a margem superior conforme o tamanho da tela */
    font-weight: bold;
  }
`;
