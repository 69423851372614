import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import axios from '../../services/axios';
import { Form } from './styled';

export default function UserPopup({ onClose }) {
  const [description, setDescription] = useState('');
  const [picture, setPicture] = useState(null);
  const [option, setOption] = useState('');
  const [subject, setSubject] = useState('');
  const user = useSelector((state) => state.auth.user.nome);

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const emojiMap = {
    Sugestão: '🤔',
    Elogio: '😊',
    Reclamação: '😞',
    'Relato de problema': '😟',
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!option || !subject || !description) {
      toast.error('Por favor, preencha todos os campos obrigatórios.');
      return;
    }
    try {
      const formData = new FormData();
      formData.append('user', user);
      formData.append('description', description);
      if (picture) {
        formData.append('picture', picture);
      }
      formData.append('option', option);
      formData.append('subject', subject);
      await axios.post('/pictures/report', formData);
      onClose();
      toast.success('Erro reportado com sucesso!!');
    } catch (error) {
      toast.error(error.message || 'Ocorreu um erro ao enviar o feedback.');
    }
  };

  const handleFileChange = (event) => {
    setPicture(event.target.files[0]);
  };

  return (
    <Dialog open onClose={onClose} fullWidth maxWidth={isSmallScreen ? 'xs' : 'md'}>
      <Form onSubmit={handleSubmit}>
        <DialogTitle>
          <h1>Reportar Feedback</h1>
        </DialogTitle>
        <DialogContent>
          <FormControl className="option-label" fullWidth margin="normal">
            <InputLabel id="option-label" style={{ fontFamily: 'Poppins' }}>
              Tipo de Feedback *
            </InputLabel>
            <Select labelId="option-label" id="option" value={option} onChange={(e) => setOption(e.target.value)}>
              <MenuItem value="Sugestão" style={{ fontFamily: 'Poppins' }}>
                Sugestão {emojiMap['Sugestão']}
              </MenuItem>
              <MenuItem value="Elogio" style={{ fontFamily: 'Poppins' }}>
                Elogio {emojiMap.Elogio}
              </MenuItem>
              <MenuItem value="Reclamação" style={{ fontFamily: 'Poppins' }}>
                Reclamação {emojiMap['Reclamação']}
              </MenuItem>
              <MenuItem value="Relato de problema" style={{ fontFamily: 'Poppins' }}>
                Relato de problema {emojiMap['Relato de problema']}
              </MenuItem>
            </Select>
          </FormControl>
          <TextField
            autoFocus
            className="text-field fixed-height visible-border"
            margin="dense"
            id="subject"
            label="Assunto *"
            type="text"
            multiline
            minRows={4}
            fullWidth
            variant="outlined"
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
            InputLabelProps={{
              style: { fontFamily: 'Poppins' },
            }}
          />
          <TextField
            className="text-field fixed-height visible-border"
            margin="dense"
            id="description"
            label="Descrição *"
            type="text"
            multiline
            minRows={4}
            fullWidth
            variant="outlined"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            InputLabelProps={{
              style: { fontFamily: 'Poppins' },
            }}
          />
          <label htmlFor="picture">
            <input accept="image/*" style={{ display: 'none' }} id="picture" type="file" onChange={handleFileChange} />
            {picture ? (
              <>
                <span>{picture.name}</span>
                <Button className="button" variant="contained" size="small" component="span">
                  Alterar
                </Button>
              </>
            ) : (
              <Button className="button" variant="contained" size="small" component="span">
                Upload
              </Button>
            )}
          </label>
        </DialogContent>
        <DialogActions>
          <Button className="Button" onClick={onClose} size="small" disableRipple>
            Cancelar
          </Button>
          <Button className="Button" type="submit" size="small" disableRipple>
            Enviar
          </Button>
        </DialogActions>
      </Form>
    </Dialog>
  );
}

UserPopup.propTypes = {
  onClose: PropTypes.func.isRequired,
};
