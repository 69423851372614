import styled from 'styled-components';

export const Form = styled.form`
  background-color: white;
  display: flex;
  width: 50%;
  height: 60%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 9999;
  transform: translate(-50%, -50%);
  border-radius: 20px;
  margin-top: 5vh;
  overflow-y: auto;

  @media screen and (max-width: 767px) {
    width: 90%;
    height: 90vw; /* A altura será igual a largura em telas menores */
  }

  h1 {
    margin: 8% 0px;
    font-size: 20px;
    @media screen and (max-width: 767px) {
      top: -50%; /* A altura será igual a largura em telas menores */
    }
  }

  label {
    display: flex;
    font-family: 'Poppins', sans-serif;
    flex-direction: column;
    margin-bottom: 30px;
  }

  .button {
    margin-top: 15px;
    width: 100%;
    height: 40px;
    margin-bottom: 10px;
    border-radius: 35px;
    text-transform: none;
    font-weight: bold;
    font-family: 'Poppins', sans-serif;
    color: white;
    background-color: #0a798d;

    &:hover {
      background-color: #00465a;
    }
  }

  .Button {
    margin: 0px 5px;
    height: 40px;
    margin-top: -20%;
    border-radius: 35px;
    width: 222px;
    color: white;
    background-color: #0a798d;
    font-weight: bold;
    font-family: 'Poppins', sans-serif;
    text-transform: none;

    &:hover {
      background-color: #00465a;
      transition-duration: 0s;
    }

    &:checked {
      background-color: #00465a;
    }

    @media screen and (max-width: 767px) {
      width: 152px; /* Metade do tamanho original */
      margin-top: -20%;
    }
  }

  .text-field {
    font-family: 'Poppins', sans-serif;

    & label.Mui-focused {
      color: #0069d9;
    }

    & .MuiOutlinedInput-root {
      &.Mui-focused fieldset {
        border-color: #0069d9;
      }
    }
  }

  .fixed-height .MuiOutlinedInput-root {
    height: auto;
    border: none;
  }

  .fixed-height .MuiOutlinedInput-multiline {
    padding: 5px;
    border: none;
  }

  .fixed-height .MuiInputBase-inputMultiline {
    height: 100px;
    border: none;
  }

  input {
    width: 100%;
    height: 80px;
    font-size: 18px;
    font-family: 'Poppins', sans-serif;
    border-radius: 4px;
    border: none;
  }

  .box-container {
    border: 2px solid #ddd;
    padding: 0px 20px;
    width: 100%;
    height: auto;
    margin-top: 20px;
    border-radius: 8px;
    margin-bottom: 0px;
    position: relative;
  }

  .box-container h1 {
    position: relative;
    padding: 0px 5px;
    z-index: 1;
    background-color: white;
  }

  .option-label {
    margin-top: -10px;
    font-family: 'Poppins', sans-serif;
  }

  @media screen and (min-width: 868px) {
    width: 580px;
    h1 {
      margin-top: 10%;
    }
  }

  @media screen and (max-width: 867px) {
    width: calc(100% - 40px);
    margin-top: 10px;

    h1 {
      margin-top: 5%;
    }
  }
`;

export const Popup = styled.div`
  position: fixed;
  width: 700px;
  height: 400px; /* Largura e altura iguais para garantir que seja um quadrado */
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  z-index: 1000;
  display: flex;
  flex-direction: column;
  align-items: center;
  scrollbar-width: none;

  @media screen and (max-width: 767px) {
    width: 90%;
    height: 50vw; /* A altura será igual a largura em telas menores */
  }
`;
