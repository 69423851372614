import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import MaskedInput from 'react-text-mask';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import PropTypes from 'prop-types';
import { Button, TextField, useMediaQuery, Grid } from '@mui/material';
import Layout from '../../components/Bug/Layout';
import Loading from '../../components/Loading';
import * as actions from '../../store/modules/auth/actions';

import axios from '../../services/axios';

import { StyledContainer, Composition, Container } from './styled';

export default function Register({ match }) {
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.auth.isLoading);
  const id = useSelector((state) => state.auth.user.id);
  const history = useHistory();

  const [formStep, setFormStep] = useState(1);
  const [formData, setFormData] = useState({
    nome: '',
    preco: '',
    estoque: '',
    unidade: '',
    custo: '',
    codBarras: '',
    descricao: '',
    categoria: '',
    NCM: '',
  });

  const [formErrors, setFormErrors] = useState({
    nome: '',
    preco: '',
    estoque: '',
    unidade: '',
  });

  const isMobile = useMediaQuery('(max-width:600px)');

  useEffect(() => {
    async function getData() {
      const { id: productId } = match.params;
      if (productId) {
        const response = await axios.get(`/product/${productId}`);
        setFormData({ ...response.data });
      }
    }

    getData();
  }, [match.params]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleNextStep = () => {
    setFormStep(formStep + 1);
  };

  const handlePrevStep = () => {
    setFormStep(formStep - 1);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const newFormErrors = {};

    if (!formData.nome) {
      newFormErrors.nome = 'Nome do produto é obrigatório';
    } else {
      newFormErrors.nome = '';
    }

    if (!formData.preco) {
      newFormErrors.preco = 'Preço do produto é obrigatório';
    } else {
      newFormErrors.preco = '';
    }

    if (!formData.estoque) {
      newFormErrors.estoque = 'Quantidade em estoque é obrigatória';
    } else {
      newFormErrors.estoque = '';
    }

    if (!formData.unidade) {
      newFormErrors.unidade = 'Unidade do produto é obrigatória';
    } else {
      newFormErrors.unidade = '';
    }

    setFormErrors(newFormErrors);

    if (Object.values(newFormErrors).some((error) => error)) {
      return; // Se houver erros, não prosseguir com o submit
    }

    const convertedPreco =
      formData.preco &&
      !Number.isNaN(
        Number(
          String(formData.preco)
            .replace(/[^0-9,.-]/g, '')
            .replace(',', '.'),
        ),
      )
        ? parseFloat(
            String(formData.preco)
              .replace(/[^0-9,.-]/g, '')
              .replace(',', '.'),
          )
        : 0;
    const convertedCusto =
      formData.custo &&
      !Number.isNaN(
        Number(
          String(formData.custo)
            .replace(/[^0-9,.-]/g, '')
            .replace(',', '.'),
        ),
      )
        ? parseFloat(
            String(formData.custo)
              .replace(/[^0-9,.-]/g, '')
              .replace(',', '.'),
          )
        : 0;

    dispatch(
      actions.editProductRequest({
        ...formData,
        preco: convertedPreco,
        custo: convertedCusto,
        userId: id,
        history,
      }),
    );
  };

  const currencyMask = createNumberMask({
    prefix: 'R$ ',
    suffix: '',
    includeThousandsSeparator: true,
    thousandsSeparatorSymbol: '.',
    allowDecimal: true,
    decimalSymbol: ',',
    decimalLimit: 2, // Quantidade de casas decimais permitidas
    integerLimit: 7, // Quantidade máxima de dígitos permitidos no número inteiro
    allowNegative: false,
    allowLeadingZeroes: false,
  });

  return (
    <Layout>
      <Container>
        <Loading isLoading={isLoading} />
        <StyledContainer>
          <form onSubmit={handleSubmit}>
            <h1>Cadastre um novo produto</h1>
            {formStep === 1 && (
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    className="text-field"
                    InputProps={{
                      disableUnderline: true,
                    }}
                    label="Nome do produto *"
                    name="nome"
                    value={formData.nome}
                    onChange={handleChange}
                    placeholder="Nome do produto"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    error={!!formErrors.nome}
                    helperText={formErrors.nome}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <MaskedInput
                    mask={currencyMask}
                    value={formData.preco}
                    onChange={handleChange}
                    render={(ref, props) => (
                      <TextField
                        className="text-field"
                        InputProps={{ inputRef: ref, disableUnderline: true }}
                        label="Preço *"
                        name="preco"
                        placeholder="Preço do produto"
                        fullWidth
                        margin="normal"
                        variant="outlined"
                        error={!!formErrors.preco}
                        helperText={formErrors.preco}
                        InputLabelProps={{ shrink: true }}
                        {...props}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    className="text-field"
                    InputProps={{ disableUnderline: true }}
                    label="Qtd. Estoque *"
                    name="estoque"
                    value={formData.estoque}
                    onChange={handleChange}
                    placeholder="Quantidade em estoque"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    error={!!formErrors.estoque}
                    helperText={formErrors.estoque}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    className="text-field"
                    InputProps={{ disableUnderline: true }}
                    label="Unidade (KD, Un, Metros) *"
                    name="unidade"
                    value={formData.unidade}
                    onChange={handleChange}
                    placeholder="Unitário, Quilograma, Metros"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    error={!!formErrors.unidade}
                    helperText={formErrors.unidade}
                  />
                </Grid>
                <Grid item xs={12} />
                <Grid item xs={12} sm={6}>
                  <MaskedInput
                    mask={currencyMask}
                    value={formData.custo}
                    onChange={handleChange}
                    render={(ref, props) => (
                      <TextField
                        className="text-field"
                        InputProps={{ inputRef: ref, disableUnderline: true }}
                        label="Preço de Custo"
                        name="custo"
                        placeholder="Preço de custo do produto"
                        fullWidth
                        margin="normal"
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                        {...props}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    className="text-field"
                    InputProps={{ disableUnderline: true }}
                    label="Código de barras"
                    name="codBarras"
                    value={formData.codBarras}
                    onChange={handleChange}
                    placeholder="Código de barras do produto"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    className="text-field"
                    InputProps={{ disableUnderline: true }}
                    label="Descrição"
                    name="descricao"
                    value={formData.descricao}
                    onChange={handleChange}
                    placeholder="Descrição do produto"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    className="text-field"
                    InputProps={{ disableUnderline: true }}
                    label="Categoria"
                    name="categoria"
                    value={formData.categoria}
                    onChange={handleChange}
                    placeholder="Categoria do produto"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    className="text-field"
                    InputProps={{ disableUnderline: true }}
                    label="NCM"
                    name="NCM"
                    value={formData.NCM}
                    onChange={handleChange}
                    placeholder="NCM do produto"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                  />
                  <Button
                    className="button"
                    type="button"
                    onClick={() => history.push('/product')}
                    variant="contained"
                    disableRipple
                  >
                    Voltar
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    className="button"
                    type="button"
                    onClick={handleNextStep}
                    variant="contained"
                    color="primary"
                    disabled={formErrors.nome || formErrors.preco || formErrors.estoque || formErrors.unidade}
                    style={{
                      padding: isMobile ? '8px 16px' : '12px 24px',
                      fontSize: isMobile ? '0.75rem' : '1rem',
                    }}
                  >
                    Próximo
                  </Button>
                </Grid>
              </Grid>
            )}
          </form>
        </StyledContainer>
        <Composition>
          {formStep === 2 && (
            <>
              <h1>Confirme dados</h1>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    className="text-field"
                    InputProps={{ disableUnderline: true }}
                    label="Nome do produto *"
                    name="nome"
                    value={formData.nome}
                    placeholder="Nome do produto *"
                    disabled
                    fullWidth
                    variant="outlined"
                    margin="normal"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    className="text-field"
                    InputProps={{ disableUnderline: true }}
                    label="Preço *"
                    name="preco"
                    value={formData.preco}
                    disabled
                    onChange={handleChange}
                    placeholder="Preço do produto"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    className="text-field"
                    InputProps={{ disableUnderline: true }}
                    label="Qtd. Estoque *"
                    name="estoque"
                    value={formData.estoque}
                    disabled
                    onChange={handleChange}
                    placeholder="Quantidade em estoque"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    className="text-field"
                    InputProps={{ disableUnderline: true }}
                    label="Unidade (KD, Un, Metros) *"
                    name="unidade"
                    value={formData.unidade}
                    disabled
                    onChange={handleChange}
                    placeholder="Unitário, Quilograma, Metros"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    className="text-field"
                    InputProps={{ disableUnderline: true }}
                    label="Preço de Custo"
                    name="custo"
                    value={formData.custo}
                    disabled
                    onChange={handleChange}
                    placeholder="Preço de custo do produto"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    className="text-field"
                    InputProps={{ disableUnderline: true }}
                    label="Código de barras"
                    name="codBarras"
                    value={formData.codBarras}
                    disabled
                    onChange={handleChange}
                    placeholder="Preço do produto"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    className="text-field"
                    InputProps={{ disableUnderline: true }}
                    label="Descrição"
                    name="descricao"
                    value={formData.descricao}
                    onChange={handleChange}
                    placeholder="Descrição do produto"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    className="text-field"
                    InputProps={{ disableUnderline: true }}
                    label="Categoria"
                    name="categoria"
                    value={formData.categoria}
                    disabled
                    onChange={handleChange}
                    placeholder="Categoria do produto"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    className="text-field"
                    InputProps={{ disableUnderline: true }}
                    label="NCM"
                    name="NCM"
                    value={formData.NCM}
                    disabled
                    onChange={handleChange}
                    placeholder="NCM do produto"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                  />

                  <Button
                    className="button"
                    type="button"
                    onClick={handlePrevStep}
                    variant="contained"
                    disableRipple
                    style={{ marginBottom: '10px' }}
                  >
                    Voltar
                  </Button>
                  <Button
                    className="button"
                    onClick={handleSubmit}
                    type="submit"
                    variant="contained"
                    color="primary"
                    disableRipple
                    style={{ marginTop: '10px' }}
                  >
                    Finalizar
                  </Button>
                </Grid>
              </Grid>
            </>
          )}
        </Composition>
      </Container>
    </Layout>
  );
}

Register.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};
