import * as types from '../types';

export function loginRequest(payload) {
  return {
    type: types.LOGIN_REQUEST,
    payload,
  };
}

export function loginSuccess(payload) {
  return {
    type: types.LOGIN_SUCCESS,
    payload,
  };
}

export function loginFailure(payload) {
  return {
    type: types.LOGIN_FAILURE,
    payload,
  };
}

export function editOtherUserRequest(payload) {
  return {
    type: types.EDIT_OTHER_USER_REQUEST,
    payload,
  };
}

export function editOtherUserSuccess(payload) {
  return {
    type: types.EDIT_OTHER_USER_SUCCESS,
    payload,
  };
}

export function editOtherUserFailure(payload) {
  return {
    type: types.EDIT_OTHER_USER_FAILURE,
    payload,
  };
}

export function editRequest(payload) {
  return {
    type: types.EDIT_REQUEST,
    payload,
  };
}

export function editSuccess(payload) {
  return {
    type: types.EDIT_SUCCESS,
    payload,
  };
}
export function editFailure(payload) {
  return {
    type: types.EDIT_FAILURE,
    payload,
  };
}

export function editClientRequest(payload) {
  return {
    type: types.EDIT_CLIENT_REQUEST,
    payload,
  };
}

export function editClientSuccess(payload) {
  return {
    type: types.EDIT_CLIENT_SUCCESS,
    payload,
  };
}
export function editClientFailure(payload) {
  return {
    type: types.EDIT_CLIENT_FAILURE,
    payload,
  };
}

export function editProductRequest(payload) {
  return {
    type: types.EDIT_PRODUCT_REQUEST,
    payload,
  };
}

export function editProductSuccess(payload) {
  return {
    type: types.EDIT_PRODUCT_SUCCESS,
    payload,
  };
}
export function editProductFailure(payload) {
  return {
    type: types.EDIT_PRODUCT_FAILURE,
    payload,
  };
}
export function registerClientRequest(payload) {
  return {
    type: types.REGISTER_CLIENT_REQUEST,
    payload,
  };
}

export function registerClientSuccess(payload) {
  return {
    type: types.REGISTER_CLIENT_SUCCESS,
    payload,
  };
}
export function registerClientFailure(payload) {
  return {
    type: types.REGISTER_CLIENT_FAILURE,
    payload,
  };
}

export function editSubmoduleRequest(payload) {
  return {
    type: types.EDIT_SUBMODULE_REQUEST,
    payload,
  };
}

export function editSubmoduleSuccess(payload) {
  return {
    type: types.EDIT_SUBMODULE_SUCCESS,
    payload,
  };
}
export function editSubmoduleFailure(payload) {
  return {
    type: types.EDIT_SUBMODULE_FAILURE,
    payload,
  };
}

export function editQuizRequest(payload) {
  return {
    type: types.EDIT_QUIZ_REQUEST,
    payload,
  };
}

export function editQuizSuccess(payload) {
  return {
    type: types.EDIT_QUIZ_SUCCESS,
    payload,
  };
}
export function editQuizFailure(payload) {
  return {
    type: types.EDIT_QUIZ_FAILURE,
    payload,
  };
}

export function editContentRequest(payload) {
  return {
    type: types.EDIT_CONTENT_REQUEST,
    payload,
  };
}

export function editContentSuccess(payload) {
  return {
    type: types.EDIT_CONTENT_SUCCESS,
    payload,
  };
}
export function editContentFailure(payload) {
  return {
    type: types.EDIT_CONTENT_FAILURE,
    payload,
  };
}

export function confirmRequest(payload) {
  return {
    type: types.CONFIRM_ACCOUNT_REQUEST,
    payload,
  };
}

export function confirmAccountSuccess(payload) {
  return {
    type: types.CONFIRM_ACCOUNT_SUCCESS,
    payload,
  };
}

export function confirmAccountFailure(payload) {
  return {
    type: types.CONFIRM_ACCOUNT_FAILURE,
    payload,
  };
}
export function registerRequest(payload) {
  return {
    type: types.REGISTER_REQUEST,
    payload,
  };
}

export function registerCreatedSuccess(payload) {
  return {
    type: types.REGISTER_CREATED_SUCCESS,
    payload,
  };
}

export function registerFailure(payload) {
  return {
    type: types.REGISTER_FAILURE,
    payload,
  };
}
export function registerCourseRequest(payload) {
  return {
    type: types.REGISTER_COURSE_REQUEST,
    payload,
  };
}

export function registerCourseCreatedSuccess(payload) {
  return {
    type: types.REGISTER_COURSE_CREATED_SUCCESS,
    payload,
  };
}

export function registerCourseFailure(payload) {
  return {
    type: types.REGISTER_COURSE_FAILURE,
    payload,
  };
}

export function registerSubmoduleRequest(payload) {
  return {
    type: types.REGISTER_SUBMODULE_REQUEST,
    payload,
  };
}

export function registerSubmoduleCreatedSuccess(payload) {
  return {
    type: types.REGISTER_SUBMODULE_CREATED_SUCCESS,
    payload,
  };
}

export function registerSubmoduleFailure(payload) {
  return {
    type: types.REGISTER_SUBMODULE_FAILURE,
    payload,
  };
}

export function registerQuizRequest(payload) {
  return {
    type: types.REGISTER_QUIZ_REQUEST,
    payload,
  };
}

export function registerQuizCreatedSuccess(payload) {
  return {
    type: types.REGISTER_QUIZ_CREATED_SUCCESS,
    payload,
  };
}

export function registerQuizFailure(payload) {
  return {
    type: types.REGISTER_QUIZ_FAILURE,
    payload,
  };
}

export function registerProductRequest(payload) {
  return {
    type: types.REGISTER_PRODUCT_REQUEST,
    payload,
  };
}

export function registerProductSuccess(payload) {
  return {
    type: types.REGISTER_PRODUCT_CREATED_SUCCESS,
    payload,
  };
}

export function registerProductFailure(payload) {
  return {
    type: types.REGISTER_PRODUCT_FAILURE,
    payload,
  };
}

export function passwordResetRequest(payload) {
  return {
    type: types.PASSWORD_RESET_REQUEST,
    payload,
  };
}

export function passwordResetSuccess() {
  return {
    type: types.PASSWORD_RESET_SUCCESS,
  };
}

export function passwordResetFailure() {
  return {
    type: types.PASSWORD_RESET_FAILURE,
  };
}

export function passwordRecoveryRequest(payload) {
  return {
    type: types.PASSWORD_RECOVERY_REQUEST,
    payload,
  };
}

export function passwordRecoverySuccess() {
  return {
    type: types.PASSWORD_RECOVERY_SUCCESS,
  };
}

export function passwordRecoveryFailure() {
  return {
    type: types.PASSWORD_RECOVERY_FAILURE,
  };
}

export function setDepartment(departamento) {
  return {
    type: types.SET_DEPARTMENT,
    payload: { departamento },
  };
}
