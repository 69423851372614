import React from 'react';
import { useHistory, Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useMediaQuery } from '@mui/material';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import AssignmentIcon from '@mui/icons-material/Assignment';
import AddBusinessIcon from '@mui/icons-material/AddBusiness';
import * as actions from '../../store/modules/auth/actions';

import { Container } from '../../styles/GlobalStyles';
import { TextContent } from './styled';
import Navbar from '../../components/NavBar';

export default function Welcome() {
  const history = useHistory();
  const dispatch = useDispatch();

  const handleLogout = (e) => {
    e.preventDefault();
    dispatch(actions.loginFailure());
    history.push('/');
  };

  // Consultas de mídia para diferentes tamanhos de tela
  const isSmallScreen = useMediaQuery('(max-width: 600px)');
  const isMediumScreen = useMediaQuery('(max-width: 960px)');

  return (
    <div>
      <Navbar onLogout={handleLogout} />
      <Container>
        <TextContent isSmallScreen={isSmallScreen} isMediumScreen={isMediumScreen}>
          <Link to="/client">
            <button type="button" className="card1">
              <PersonAddIcon
                style={{ fontSize: isSmallScreen ? '30px' : '40px', marginBottom: '10px', color: '#0a798d' }}
              />
              <p style={{ fontSize: isSmallScreen ? '14px' : '20px', color: '#0a798d' }}>Gestão de Cliente</p>
            </button>
          </Link>
          <Link to="/product">
            <button type="button" className="card2">
              <AddBusinessIcon
                style={{ fontSize: isSmallScreen ? '30px' : '40px', marginBottom: '10px', color: '#0a798d' }}
              />
              <p style={{ fontSize: isSmallScreen ? '14px' : '20px', color: '#0a798d' }}>Gestão de Produto</p>
            </button>
          </Link>
          <Link to="/Das">
            <button type="button" className="card3">
              <AssignmentIcon
                style={{ fontSize: isSmallScreen ? '30px' : '40px', marginBottom: '10px', color: '#0a798d' }}
              />
              <p style={{ fontSize: isSmallScreen ? '14px' : '20px', color: '#0a798d' }}>DAS</p>
            </button>
          </Link>
        </TextContent>
      </Container>
    </div>
  );
}
