// src/components/Layout.js
import React from 'react';
import PropTypes from 'prop-types'; // Certifique-se de importar PropTypes
import styled from 'styled-components';
import Navbar from '../../NavBar';

const MainContent = styled.main`
  padding: 20px;
`;

function Layout({ children }) {
  return (
    <>
      <Navbar onLogout={() => console.log('Logout')} />
      <MainContent>{children}</MainContent>
    </>
  );
}

// Adicione a validação de PropTypes
Layout.propTypes = {
  children: PropTypes.node.isRequired, // Valida que `children` é um nó React
};

export default Layout;
