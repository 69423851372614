import styled from 'styled-components';

const getWidth = (props) => {
  if (props.isSmallScreen) return 'calc(100% - 10px)';
  if (props.isTabletScreen) return '100%';
  return '110%';
};

const getHeight = (props) => {
  if (props.isSmallScreen) return '100%';
  if (props.isTabletScreen) return '150%';
  return '70%';
};

const getPadding = (props) => {
  if (props.isSmallScreen) return '30 0px';
  if (props.isTabletScreen) return '30px 0px';
  return '30px';
};

const getMarginTop = (props) => {
  if (props.isSmallScreen) return '20%';
  if (props.isTabletScreen) return '40%';
  return '-20%';
};

const getH1MarginTop = (props) => {
  if (props.isSmallScreen) return '-60%';
  if (props.isTabletScreen) return '-40%';
  return '-40%';
};

export const Container = styled.section`
  width: 100%;
  height: 100vh;
  background: #fff; /* Fundo branco */
  overflow: hidden;
  overflow-y: auto;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  justify-content: center;
  z-index: 0; /* Mantém abaixo de Nav e SecondNav */
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: -7%;

  .logo-box {
    width: 70%;
    max-width: 470px;
    object-fit: contain;
    display: flex;
    text-align: center;
    margin-left: 10%;
    margin-top: ${(props) => getMarginTop(props)};
    justify-content: center;
    margin-bottom: ${(props) => (props.isSmallScreen ? '10%' : '5%')};
  }

  .box-container {
    width: ${(props) => getWidth(props)};
    height: ${(props) => getHeight(props)};
    max-width: ${(props) => (props.isSmallScreen ? '100vh' : '500px')};
    padding: ${(props) => getPadding(props)};
    border: 1px solid #ccc;
    border-radius: ${(props) => (props.isSmallScreen ? '0' : '10px')};
    background-color: #fff;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    margin-bottom: -10px;
    margin-top: ${(props) => (props.isSmallScreen ? '5%' : '37%')};
    box-sizing: border-box;
    position: ${(props) => (props.isSmallScreen ? 'absolute' : 'static')};
    top: ${(props) => (props.isSmallScreen ? '0' : 'auto')};
    left: ${(props) => (props.isSmallScreen ? '5' : 'auto')};
    transform: ${(props) => (props.isSmallScreen ? 'none' : 'none')};

    @media (max-width: 768px) {
      padding: 0 20px;
      margin: 0;
    }

    @media (min-width: 769px) and (max-width: 1024px) {
      width: 100%;
      height: 100%;
      padding: 10px 20px;
      margin: 0;
    }
  }

  h1 {
    font-size: 24px;
    text-align: center;
    margin-bottom: 0px;
    margin-top: ${(props) => getH1MarginTop(props)};
    color: #333;
  }

  p {
    text-align: center;
    margin-bottom: 30px;
    margin-top: 5%;
  }
  .button {
    margin-top: 15px;
    width: 100%;
    height: 40px;
    margin-bottom: 10px;
    border-radius: 35px;
    text-transform: none;
    font-weight: bold;
    font-family: 'Poppins', sans-serif;
    color: white;
    background-color: #0a798d;

    &:hover {
      background-color: #00465a;
    }
  }
`;
