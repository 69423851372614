import React, { useEffect, useState } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { FaEdit } from 'react-icons/fa';
import { TableBody, TableCell, TableHead, TableRow, Divider, useMediaQuery } from '@mui/material';
import get from 'lodash/get';
import Modal from '@mui/material/Modal';
import { Container, StyledContainer, Bloco, Icon, TableContainer, StyledTable, ResponsiveButton } from './styled';
import axios from '../../services/axios';
import Loading from '../../components/Loading';
import Navbar from '../../components/NavBar';

export default function AdjustClient() {
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const id = useSelector((state) => state.auth.user.id);
  const [clients, setClients] = useState([]);
  const isSmallScreen = useMediaQuery('(max-width: 768px)');
  const history = useHistory(); // Importa useHistory

  useEffect(() => {
    async function getData() {
      setIsLoading(true);
      try {
        const response = await axios.get('/client');
        const meiClient = response.data.filter((client) => client.userId === id);
        setClients(meiClient);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    }
    getData();
  }, [id]);

  const handleConfirmOpen = (clientId) => {
    setDeleteId(clientId);
    setConfirmOpen(true);
  };

  const handleConfirmClose = () => setConfirmOpen(false);

  const handleDelete = async () => {
    if (deleteId === null) return;

    try {
      setIsLoading(true);
      await axios.delete(`/client/${deleteId}`);
      setClients(clients.filter((client) => client.id !== deleteId));
      toast.success('Cliente excluído com sucesso');
    } catch (err) {
      const status = get(err, 'response.status', 0);
      if (status === 401) {
        toast.error('Você precisa fazer login');
      } else {
        toast.error('Ocorreu um erro ao excluir o cliente');
      }
    } finally {
      setIsLoading(false);
      handleConfirmClose();
    }
  };

  const handleBack = () => {
    history.push('/client'); // Navega para a página /client
  };

  return (
    <div>
      <Navbar />
      <Container isSmallScreen={isSmallScreen}>
        <Loading isLoading={isLoading} />
        <StyledContainer isSmallScreen={isSmallScreen}>
          <h1 className="head">Ajustar Clientes</h1>
          <h4>Atualize informações de clientes existentes</h4>
          <TableContainer>
            <StyledTable>
              <TableHead>
                <TableRow>
                  <TableCell>Nome</TableCell>
                  <TableCell>Editar</TableCell>
                  <TableCell>Excluir</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {clients.length > 0 ? (
                  clients.map((client) => (
                    <TableRow key={client.id}>
                      <TableCell>{client.nome}</TableCell>
                      <TableCell>
                        <Link to={`/edit-client/${client.id}`}>
                          <FaEdit color="black" size={16} />
                        </Link>
                      </TableCell>
                      <TableCell>
                        <Icon
                          onClick={() => handleConfirmOpen(client.id)}
                          viewBox="0 0 15 17.5"
                          height="17.5"
                          width="15"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            transform="translate(-2.5 -1.25)"
                            d="M15,18.75H5A1.251,1.251,0,0,1,3.75,17.5V5H2.5V3.75h15V5H16.25V17.5A1.251,1.251,0,0,1,15,18.75ZM5,5V17.5H15V5Zm7.5,10H11.25V7.5H12.5V15ZM8.75,15H7.5V7.5H8.75V15ZM12.5,2.5h-5V1.25h5V2.5Z"
                          />
                        </Icon>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={3} style={{ textAlign: 'center' }}>
                      Nenhum cliente encontrado.
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </StyledTable>
          </TableContainer>
          <Divider style={{ margin: '20px 0' }} />
        </StyledContainer>
        <Modal open={confirmOpen} onClose={handleConfirmClose}>
          <Bloco>
            <div className="box-container">
              <h2>Confirmação</h2>
              <p>Tem certeza de que deseja deletar este cliente?</p>
              <button type="button" onClick={handleDelete}>
                Sim
              </button>
              <button type="button" onClick={handleConfirmClose}>
                Não
              </button>
            </div>
          </Bloco>
        </Modal>
        <ResponsiveButton
          className="button"
          variant="contained"
          color="secondary"
          isSmallScreen={isSmallScreen}
          onClick={handleBack} // Adiciona o evento onClick
        >
          Voltar
        </ResponsiveButton>
      </Container>
    </div>
  );
}
