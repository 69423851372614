import styled, { createGlobalStyle } from 'styled-components';
import 'react-toastify/dist/ReactToastify.css';

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  .MuiTextField-root {
    width: 100%;
    margin-bottom: ${(props) => (props.isSmallScreen ? '15px' : '5px')};
    margin-top: 0; /* Ajuste conforme necessário */

    & .MuiOutlinedInput-root {
      height: 40px !important;
      border-radius: 35px;
      box-shadow: none; /* Remove a sombra padrão */

      &.Mui-focused {
        height: 40px !important;

        & fieldset {
          border-color: grey; /* Altere a cor do foco */
        }
      }

      &.MuiAutocomplete-root {
        & .MuiOutlinedInput-notchedOutline {
          border-color: grey; /* Altere a cor do foco do autocomplete */
        }
      }
    }

    & label {
      font-family: "Poppins", sans-serif;
      font-size: 14px;
      top: -10%;
      color: grey;

      &.Mui-focused {
        color: red; /* Altere a cor do texto do label quando focado */
      }
    }
  }

  a {
    text-decoration: none;
    font-family: "Poppins", sans-serif;
  }

  h1, h4, p {
    font-family: "Poppins", sans-serif;
  }
`;

export const Container = styled.section`
  overflow: hidden; /* Esconde qualquer overflow */
  overflow-y: auto;
  transition: margin-left 0.5s ease;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  height: 100vh;
  z-index: 1;
  background: #fff;
`;
