import React, { useState } from 'react';
import { TextField, Button, Typography } from '@mui/material';
import axios from '../../services/axios'; // Adicione a extensão .js
import { Container, Title, FormRow } from './styled';
import Navbar from '../../components/NavBar';

function DASVisualization() {
  const [selectedYear, setSelectedYear] = useState('');
  const [cnpj, setCnpj] = useState('');
  const [dasData, setDasData] = useState(null);
  const [error, setError] = useState('');

  const handleLogout = () => {
    console.log('Usuário deslogado');
  };

  const handleButtonClick = async () => {
    const token = 'DE7B9EB7-A241-44A0-999D-B3A81ED539FD'; // Substitua pelo seu token de acesso
    const year = selectedYear;
    const plugin = 'PGMEI';
    const url = `/api/v1/execute-api.php?token=${token}&cnpj=${cnpj}&ano=${year}&plugin=${plugin}`;

    try {
      const response = await axios.get(url);
      console.log('Resposta da API:', response);
      const { data } = response;

      // Verifique se a resposta contém o status OK
      if (data.status === 'OK') {
        setDasData(data);
        setError(''); // Limpa erros anteriores, se houver
      } else {
        // Se o status não for OK, defina a mensagem de erro
        setDasData(null);
        setError(data.message || 'Erro desconhecido.', err);
      }
    } catch (err) {
      console.error('Erro na requisição:', err); // Loga o erro no console
      setDasData(null);
      console.error('Erro na requisição:', err);
      setError('Ocorreu um erro ao buscar os dados.', err); // Mensagem para o usuário
      console.error('Erro na requisição:', err);
    }
  };

  return (
    <div>
      <Navbar onLogout={handleLogout} />
      <Container>
        <Title variant="h4" gutterBottom>
          Visualização de DAS
        </Title>

        <FormRow>
          <TextField
            label="Selecione o ano"
            type="text"
            value={selectedYear}
            onChange={(e) => setSelectedYear(e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
            fullWidth
            placeholder="Digite o ano (YYYY)"
          />
          <TextField label="CNPJ" value={cnpj} onChange={(e) => setCnpj(e.target.value)} fullWidth />
          <Button variant="contained" color="primary" onClick={handleButtonClick}>
            Atualizar DAS
          </Button>
        </FormRow>

        {error && (
          <Typography color="error" variant="body1" gutterBottom>
            {error}
          </Typography>
        )}

        {dasData && (
          <div>
            <Typography variant="h6">Dados da Empresa:</Typography>
            <Typography variant="body1">CNPJ: {dasData.cnpj}</Typography>
            <Typography variant="body1">Nome Empresarial: {dasData.nome_empresarial}</Typography>

            <Typography variant="h6" style={{ marginTop: '20px' }}>
              Guias de Pagamento:
            </Typography>
            {dasData.guia_pagamento.map((guia) => (
              <div key={guia.data_vencimento} style={{ marginBottom: '10px' }}>
                <Typography variant="body1">Período de Apuração: {guia.periodo_apuracao}</Typography>
                <Typography variant="body1">Ano: {guia.ano}</Typography>
                <Typography variant="body1">Apuração: {guia.apuracao}</Typography>
                <Typography variant="body1">Valor Principal: {guia.principal}</Typography>
                <Typography variant="body1">Total: {guia.total}</Typography>
                <Typography variant="body1">Data de Vencimento: {guia.data_vencimento}</Typography>
                <Typography variant="body1">Data de Acolhimento: {guia.data_acolhimento}</Typography>
              </div>
            ))}
          </div>
        )}
      </Container>
    </div>
  );
}

export default DASVisualization;
