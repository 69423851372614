import styled from 'styled-components';

export const Composition = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: -7%;

  .logo-box {
    width: 70%;
    max-width: 470px;
    object-fit: contain;
    display: flex;
    margin-left: 10%;
    justify-content: center;
    margin-bottom: ${(props) => (props.isSmallScreen ? '5%' : '-5%')};
    margin-top: ${(props) => (props.isSmallScreen ? '-7%' : '-15%')};
  }

  .box-container {
    width: ${(props) => (props.isSmallScreen ? 'calc(100% - 0px)' : '110%')}; /* Ajuste para telas pequenas */
    height: ${(props) => (props.isSmallScreen ? '100%' : '80%')};
    max-width: ${(props) => (props.isSmallScreen ? 'none' : '500px')};
    border: 1px solid #ccc;
    border-radius: ${(props) => (props.isSmallScreen ? '0' : '10px')};
    background-color: #fff;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    margin-bottom: -10px;
    padding: 20px 20px 20px 20px;
    margin-top: ${(props) => (props.isSmallScreen ? '-5%' : '27%')};
    box-sizing: border-box;
    position: ${(props) => (props.isSmallScreen ? 'absolute' : 'static')};
    top: ${(props) => (props.isSmallScreen ? '0' : 'auto')};
    left: ${(props) => (props.isSmallScreen ? '0' : 'auto')};
    transform: ${(props) => (props.isSmallScreen ? 'none' : 'none')};
  }

  h1 {
    font-size: 24px;
    text-align: center;
    margin-bottom: 30px;
    margin-top: ${(props) => (props.isSmallScreen ? '-60%' : '-40%')};
    color: #333;
  }

  p {
    text-align: center;
    margin-bottom: 30px;
    margin-top: 5%;
  }

  .button {
    margin-top: 15px;
    width: 100%;
    height: ${(props) => (props.isSmallScreen ? '40px' : '40px')};
    margin-bottom: 10px;
    border-radius: 35px;
    text-transform: none;
    font-weight: bold;
    font-family: 'Poppins', sans-serif;
    color: white;
    background-color: #0a798d;

    &:hover {
      background-color: #00465a;
    }
  }
`;

export const Form = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: -7%;
`;

export const Container = styled.section`
  width: 100%;
  height: 100vh;
  background: #fff;
  overflow: hidden;
  overflow-y: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  z-index: 0;
`;
