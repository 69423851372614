import styled from 'styled-components';
import breakpoints from '../../styles/breakpoints';

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: ${(props) => (props.isSmallScreen ? '-7%' : '10%')};

  @media (max-width: 1024px) {
    margin-top: ${(props) => (props.isSmallScreen ? '10%' : '40%')};
  }
`;

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1000px;

  h1 {
    font-size: 24px;
    text-align: center;
    margin-bottom: 20px;
    color: #333;
  }

  p {
    font-size: 15px;
    text-align: center;
    margin: 0 70px;
    margin-top: ${(props) => (props.isSmallScreen ? '5%' : '0%')};
    color: #333;
  }

  h4 {
    font-size: 20px;
    text-align: center;
    margin-bottom: 10px;
    color: #333;
  }

  .text-field {
    width: 100%;
    margin-bottom: -15px;
    font-family: 'Poppins', sans-serif;

    @media (max-width: 768px) {
      width: 100%;
    }

    & label.Mui-focused {
      color: blue;
    }

    & .MuiOutlinedInput-root {
      border-radius: 35px;

      &.Mui-focused fieldset {
        border-color: blue;
      }
    }

    @media ${breakpoints.lg} {
      width: 100%;
    }
  }

  .RadioGroup {
    margin: -5px 70px;
    margin-bottom: -50px;
  }

  .FormControlLabel {
    margin: 40px 0px;
    margin-bottom: -5px;
  }

  button {
    margin-top: 30px;
    width: 100%;
    max-width: 32%;
    height: 40px;
    border-radius: 35px;
    text-transform: none;
    margin-bottom: -4px;
    font-weight: bold;
    font-family: 'Poppins', sans-serif;
    color: white;
    background-color: #0a798d;

    &:hover {
      background-color: #00465a;
    }

    @media (max-width: 768px) {
      width: 100%;
      max-width: 100%;
    }
  }

  .PasswordChecklist {
    font-family: 'Poppins', sans-serif;
  }
`;

export const Composition = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  h4 {
    font-size: 20px;
    text-align: center;
    margin-bottom: 10px;
    color: #333;
  }

  .text-field {
    width: 80%;
    margin-bottom: ${(props) => (props.isSmallScreen ? '15px' : '5px')};
    font-family: 'Poppins', sans-serif;

    @media (max-width: 768px) {
      width: 100%;
    }

    & label.Mui-focused {
      color: blue;
    }

    & .MuiOutlinedInput-root {
      border-radius: 35px;

      &.Mui-focused fieldset {
        border-color: blue;
      }
    }
  }
`;
