import React, { useEffect, useState } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { Button, Divider } from '@mui/material';
import get from 'lodash/get';
import Modal from '@mui/material/Modal';

import { Container, StyledContainer, Bloco } from './styled';
import axios from '../../services/axios';
import Loading from '../../components/Loading';
import Navbar from '../../components/NavBar';

export default function User() {
  const [confirmOpen, setConfirmOpen] = React.useState(false);
  const history = useHistory();

  const handleConfirmClose = () => setConfirmOpen(false);

  const [isLoading, setIsLoading] = React.useState(false);
  const id = useSelector((state) => state.auth.user.id);
  const [clients, setClients] = useState([]);

  const handleLogout = (e) => {
    e.preventDefault();
    dispatch(actions.loginFailure());
    history.push('/');
  };

  useEffect(() => {
    async function getData() {
      setIsLoading(true);
      try {
        const response = await axios.get('/client');
        const meiClient = response.data.filter((client) => client.userId === id);
        setClients(meiClient);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    }

    getData();
  }, [id]);

  const handleDelete = async () => {
    if (deleteId === null) return;

    try {
      setIsLoading(true);
      await axios.delete(`/client/${deleteId}`);
      const newClients = clients.filter((client) => client.id !== deleteId);
      setClients(newClients);
      setIsLoading(false);
      toast.success('Cliente excluído com sucesso');
    } catch (err) {
      const status = get(err, 'response.status', 0);

      if (status === 401) {
        toast.error('Você precisa fazer login');
      } else {
        toast.error('Ocorreu um erro ao excluir o cliente');
      }

      setIsLoading(false);
    }

    handleConfirmClose();
  };

  return (
    <div>
      <Navbar onLogout={handleLogout} />
      <Container>
        <Loading isLoading={isLoading} />

        <StyledContainer>
          <h1>Cadastrar Cliente</h1>
          <Link className="button" to="/create-client">
            {' '}
            Novo Clinte{' '}
          </Link>
          <Button variant="contained" color="secondary" onClick={() => history.push('/adjust-client')}>
            Ajustar Cliente
          </Button>
          <Button className="button" variant="contained" color="primary" onClick={() => history.push('/home')}>
            Voltar
          </Button>
          <Divider />
        </StyledContainer>

        <Modal open={confirmOpen} onClose={handleConfirmClose}>
          <Bloco>
            <div className="box-container">
              <h2>Confirmação</h2>
              <p>Tem certeza de que deseja deletar este setor?</p>
              <button type="button" onClick={handleDelete}>
                Sim
              </button>
              <button type="button" onClick={handleConfirmClose}>
                Não
              </button>
            </div>
          </Bloco>
        </Modal>
      </Container>
    </div>
  );
}
