import React from 'react';
import { Switch } from 'react-router-dom';

import MyRoute from './MyRoute';

import home from '../pages/Home';
import index from '../pages/Index';
import cadastro from '../pages/Cadastro';
import recovery from '../pages/Recovery';
import passwordRecoveryPage from '../pages/ResetPassword';
import confirm from '../pages/ConfirmAccount';
import client from '../pages/Client';
import product from '../pages/Product';
import clientManagement from '../pages/ManagementClient';
import productManagement from '../pages/ManagementProduct';
import clientEdit from '../pages/ClientEdit';
import productEdit from '../pages/ProductEdit';
import AdjustClient from '../pages/AdjustClient';
import AdjustProduct from '../pages/AdjustProduct';
import DASPage from '../pages/DASVisualization';

export default function Routes() {
  return (
    <Switch>
      <MyRoute exact path="/" component={home} isClosed={false} />
      <MyRoute exact path="/confirm/:token" component={confirm} />
      <MyRoute exact path="/recovery" component={recovery} isClosed={false} />
      <MyRoute exact path="/cadastro" component={cadastro} isClosed={false} />
      <MyRoute exact path="/home" component={index} />
      <MyRoute exact path="/create-client" component={client} />
      <MyRoute exact path="/create-product" component={product} />
      <MyRoute exact path="/client" component={clientManagement} />
      <MyRoute path="/recovery-password" component={passwordRecoveryPage} />
      <MyRoute exact path="/product" component={productManagement} />
      <MyRoute exact path="/edit-client/:id" component={clientEdit} />
      <MyRoute exact path="/edit-product/:id" component={productEdit} />
      <MyRoute exact path="/adjust-client" component={AdjustClient} />
      <MyRoute exact path="/adjust-product" component={AdjustProduct} />
      <MyRoute exact path="/das" component={DASPage} />
    </Switch>
  );
}
