import styled from 'styled-components';
import breakpoints from '../../styles/breakpoints';

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: ${(props) => (props.isSmallScreen ? '-7%' : '10%')};

  @media (max-width: 768px) {
    margin-top: ${(props) => (props.isSmallScreen ? '-15%' : '40%')};
  }
`;

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1000px;

  h1 {
    font-size: 24px;
    text-align: center;
    margin-bottom: 20px;
    color: #333;
    margin-top: ${(props) => (props.isSmallScreen ? '-15%' : '40%')};

    @media ${breakpoints.md} {
      margin-top: -50%;
    }

    @media ${breakpoints.lg} {
      margin-top: -50%;
    }

    @media (max-width: 1466px) {
      margin-top: 90%;
    }
  }

  button {
    margin-top: 30px;
    width: 100%;
    max-width: 100%;
    height: 40px;
    border-radius: 35px;
    text-transform: none;
    margin-bottom: -4px;
    font-weight: bold;
    font-family: 'Poppins', sans-serif;
    color: white;
    background-color: #0a798d;

    &:hover {
      background-color: #00465a;
    }

    @media (max-width: 768px) {
      width: 100%;
      max-width: 100%;
    }
  }
  .button {
    margin-top: 30px;
    width: 100%;
    max-width: 100%;
    height: 40px;
    border-radius: 35px;
    text-transform: none;
    margin-bottom: -4px;
    font-weight: bold;
    font-family: 'Poppins', sans-serif;
    color: white;
    display: flex;
    text-align: center;
    align-items: center; /* Alinha o texto verticalmente */
    justify-content: center;
    background-color: #0a798d;

    &:hover {
      background-color: #00465a;
    }

    @media (max-width: 768px) {
      width: 100%;
      max-width: 100%;
    }
  }
`;

export const Bloco = styled.div`
  background-color: white;
  display: flex;
  width: 40vh;
  height: 30vh;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 55%;
  left: 50%;
  z-index: 9999;
  overflow: hidden;
  transform: translate(-50%, -50%);
  border-radius: 20px;
  margin-top: 5vh;
  padding: 10px 20px;
  margin: -10px 25px;

  .box-container {
    border: 2px solid #ddd;
    padding: 20px;
    width: 601px;
    border-radius: 8px;
    margin-bottom: 10px;
  }

  .box-container h1 {
    position: relative;
    padding: 0px 5px;
    z-index: 1;
    margin-right: 55%;
    background-color: white;
  }

  button {
    justify-content: center;
    margin: 20px 15px;
    width: 35%;
    color: white;
    background-color: #ae1100;
    font-family: 'MyFont';
    font-weight: bold;
    z-index: 1000;
    text-transform: none;
    border: none;
    &:hover {
      background-color: #ae1100;
      transition-duration: 0s;

      &:checked {
        background-color: #0197f6;
      }
    }
  }
`;
