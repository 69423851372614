import styled from 'styled-components';

export const Button = styled.button`
  width: 50px;
  height: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 3px;
  background-color: transparent;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.2s;
  position: fixed; /* Faz o botão flutuar */
  bottom: 20px; /* Posiciona o botão 20px acima do fundo da tela */
  right: 20px; /* Posiciona o botão 20px à esquerda da borda direita da tela */
  overflow: hidden;

  &:hover {
    background-color: #0a798d;
    overflow: visible;
  }

  &:active {
    transform: scale(0.98);
  }
`;

export const Tooltip = styled.span`
  position: absolute;
  top: -40px;
  color: #0a798d;
  border-radius: 5px;
  font-size: 12px;
  padding: 8px 12px;
  font-weight: 600;
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.105);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: all 0.5s;
  min-width: 100px;

  &::before {
    position: absolute;
    width: 10px;
    height: 10px;
    transform: rotate(45deg);
    content: '';
    background-color: var(--tooltip-color);
    bottom: -10%;
  }

  ${Button}:hover & {
    opacity: 1;
  }
`;
