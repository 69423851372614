const size = {
  xm: '466px',
  sm: '600px', // Small devices (tablets, 600px and up)
  md: '695px', // Medium devices (small laptops, 900px and up)
  xd: '778px',
  lg: '1213px', // Large devices (desktops, 1200px and up)
  xl: '1660px',
};

const breakpoints = {
  xm: `(max-width: ${size.sm})`,
  sm: `(max-width: ${size.sm})`,
  xd: `(max-width: ${size.xd})`,
  md: `(max-width: ${size.md})`,
  lg: `(max-width: ${size.lg})`,
  xl: `(max-width: ${size.xl})`,
};

export default breakpoints;
