import React, { useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Button, useMediaQuery } from '@mui/material';
import Loading from '../../components/Loading';
import { ReactComponent as Logo } from '../../assets/img/Logo  (1).svg';
import * as actions from '../../store/modules/auth/actions';
import { Form, Container } from './styled';

function ConfirmAccountPage() {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const { token } = useParams();
  const history = useHistory();

  const tokenWithoutColon = token.replace(/:/g, '');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    await dispatch(actions.confirmRequest({ tokenWithoutColon, history }));
    setIsLoading(false);
    history.push('/'); // Redireciona para a página Home
  };

  // useMediaQuery hook to check screen size
  const isSmallScreen = useMediaQuery('(max-width: 600px)');

  return (
    <Container isSmallScreen={isSmallScreen}>
      <Loading isLoading={isLoading} />
      <Form isSmallScreen={isSmallScreen} onSubmit={handleSubmit}>
        <div className="box-container">
          <Logo className="logo-box" />
          <h1>Confirmação de Conta</h1>
          <p> Você está prestes a conseguir todas as facilidades de ser um QuickMei!</p>
          <Button className="button" type="submit" variant="contained" color="primary" fullWidth>
            Confirmar Conta
          </Button>
        </div>
      </Form>
    </Container>
  );
}

export default ConfirmAccountPage;
