import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { FaBug } from 'react-icons/fa';
import { Button, Tooltip } from './styled';
import Report from '../Report';

export default function BugReport() {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);

  return (
    <>
      {isLoggedIn && (
        <Button onClick={() => setIsPopupOpen(true)}>
          {/* Use o componente do SVG aqui */}
          <FaBug className="bug" style={{ color: '#FF3131', width: '30px', height: '12em' }} />
          <Tooltip>Feedback</Tooltip>
        </Button>
      )}
      {isPopupOpen && <Report onClose={() => setIsPopupOpen(false)} />}
    </>
  );
}
