import styled from 'styled-components';
import breakpoints from '../../styles/breakpoints';

// Funções auxiliares para calcular os valores dinamicamente
const getWidth = (props) => {
  if (props.isSmallScreen) return 'calc(100% - 10px)';
  if (props.isTabletScreen) return '100%';
  return '110%';
};

const getHeight = (props) => {
  if (props.isSmallScreen) return '100%';
  if (props.isTabletScreen) return '150%';
  return '80%';
};

const getPadding = (props) => {
  if (props.isSmallScreen) return '30 0px';
  if (props.isTabletScreen) return '30px 0px';
  return '30px';
};

const getMarginTop = (props) => {
  if (props.isSmallScreen) return '5%';
  if (props.isTabletScreen) return '40%';
  return '-30%';
};

const getH1MarginTop = (props) => {
  if (props.isSmallScreen) return '-60%';
  if (props.isTabletScreen) return '-40%';
  return '-40%';
};

export const Form = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: -7%;

  .logo-box {
    width: 70%;
    max-width: 470px;
    object-fit: contain;
    display: flex;
    text-align: center;
    margin-left: 10%;
    margin-top: ${(props) => getMarginTop(props)};
    justify-content: center;
    margin-bottom: ${(props) => (props.isSmallScreen ? '-5%' : '-5%')};

    @media ${breakpoints.lg} {
      width: 60%;
      margin-left: 16%;
      margin-bottom: 5%;
      text-align: center;
      margin-top: -30%;
    }

    @media (max-width: 775px) {
      margin-top: -20%;
    }
  }

  .box-container {
    width: ${(props) => getWidth(props)};
    height: ${(props) => getHeight(props)};
    max-width: ${(props) => (props.isSmallScreen ? '100vh' : '500px')};
    max-width: ${(props) => (props.isTabletScreen ? '100vh' : '500px')};
    padding: ${(props) => getPadding(props)};
    border: 1px solid #ccc;
    border-radius: ${(props) => (props.isSmallScreen ? '0' : '10px')};
    background-color: #fff;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    margin-bottom: -10px;
    margin-top: ${(props) => (props.isSmallScreen ? '-180%' : '27%')};
    box-sizing: border-box;
    position: ${(props) => (props.isSmallScreen ? 'absolute' : 'static')};
    top: ${(props) => (props.isSmallScreen ? '-50px' : 'auto')};
    left: ${(props) => (props.isSmallScreen ? '5' : 'auto')};
    transform: ${(props) => (props.isSmallScreen ? 'none' : 'none')};

    @media (max-width: 826px) {
      width: 100%;
      height: 100vh;
      margin-top: -20px;
    }

    @media (max-width: 885px) {
      margin-top: -20px;
    }

    @media (max-width: 692px) {
      margin-top: -15px;
      max-height: 100vh;
    }

    @media (max-width: 1025px) {
      margin-top: -0%;
      padding: 20px;
    }

    @media ${breakpoints.lg} {
      margin-top: 20%;
      height: auto;
    }

    @media ${breakpoints.md} {
      margin-top: 10%;
      height: 100vh;
    }

    @media ${breakpoints.sm} {
      margin-top: 10%;
      height: 100vh;
    }
    @media ${breakpoints.xm} {
      margin-top: 13%;
      height: 100vh;
    }
  }

  h1 {
    font-size: 24px;
    text-align: center;
    margin-bottom: 0px;
    margin-top: ${(props) => getH1MarginTop(props)};
    color: #333;

    @media (max-width: 768px) {
      margin-top: -20%;
    }

    @media ${breakpoints.lg} {
      margin-top: -40%;
      height: auto;
    }

    @media ${breakpoints.xm} {
      margin-top: -55%;
    }
  }

  p {
    text-align: center;
    margin-bottom: 30px;
    margin-top: 5%;
    color: red;
  }

  .text-field {
    width: 100%;
    margin-top: 5%;
    font-family: 'Poppins', sans-serif;

    & label.Mui-focused {
      color: blue;
    }

    & .MuiOutlinedInput-root {
      border-radius: 35px;

      &.Mui-focused fieldset {
        border-color: blue;
      }
    }
    @media ${breakpoints.lg} {
      width: 100%;
    }
  }

  .button {
    margin-top: 15px;
    width: 100%;
    height: 40px;
    margin-bottom: 10px;
    border-radius: 35px;
    text-transform: none;
    font-weight: bold;
    font-family: 'Poppins', sans-serif;
    color: white;
    background-color: #0a798d;

    &:hover {
      background-color: #00465a;
    }
  }
`;

export const Container = styled.section`
  width: 100%;
  height: 100vh;
  background: #fff;
  overflow: hidden;
  overflow-y: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  z-index: 0;
`;
