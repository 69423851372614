import React from 'react';
import { toast } from 'react-toastify';
import { isEmail } from 'validator';
import { useDispatch, useSelector } from 'react-redux';
import { get } from 'lodash';
import { Link } from 'react-router-dom';
import { TextField, Button, InputAdornment, IconButton, useMediaQuery } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { ReactComponent as Logo } from '../../assets/img/Logo  (1).svg';
import { Container } from '../../styles/GlobalStyles';
import { Form } from './styled';
import * as actions from '../../store/modules/auth/actions';
import Loading from '../../components/Loading';
import axios from '../../services/axios'; // Certifique-se de importar o axios

export default function Login(props) {
  const dispatch = useDispatch();
  const prevPath = get(props, 'location.state.prevPath', '/');
  const history = get(props, 'history');
  const isLoading = useSelector((state) => state.auth.isLoading);
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [showPassword, setShowPassword] = React.useState(false);
  const [isComponentMounted, setIsComponentMounted] = React.useState(false);

  const isSmallScreen = useMediaQuery('(max-width: 768px)');
  const isTabletScreen = useMediaQuery('(min-width: 769px) and (max-width: 1024px)');
  const isLargeScreen = useMediaQuery('(min-width: 1025px)');

  React.useEffect(() => {
    setIsComponentMounted(true);
    console.log('useEffect executed');
    console.log('Is small screen:', isSmallScreen);
    console.log('Is tablet screen:', isTabletScreen);
    console.log('Is large screen:', isLargeScreen);

    async function getData() {
      console.log('Fetching data...'); // Log para verificar a chamada da API
      try {
        const response = await axios.get('http://localhost:4100/product'); // Verifique se a URL está correta
        console.log('API response:', response);
        // Faça algo com a resposta, se necessário
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }

    getData();

    return () => {
      setIsComponentMounted(false);
    };
  }, [isSmallScreen, isTabletScreen, isLargeScreen]); // Adicionado dependências para re-executar o useEffect quando os valores mudarem

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let formErrors = false;

    if (!isEmail(email)) {
      formErrors = true;
      toast.error('E-mail inválido.');
    }

    if (password.length < 6 || password.length > 50) {
      formErrors = true;
      toast.error('Senha inválida');
    }

    if (formErrors) return;

    console.log('Submitting form', { email, password, prevPath, history });
    dispatch(actions.loginRequest({ email, password, prevPath, history }));
  };

  return (
    isComponentMounted && (
      <Container>
        <Loading isLoading={isLoading} />
        <Form isSmallScreen={isSmallScreen} isTabletScreen={isTabletScreen} isLargeScreen={isLargeScreen}>
          <div className="box-container">
            <div className="logo-box">
              <Logo />
            </div>
            <h1 className="title">Bem Vindo</h1>
            <form onSubmit={handleSubmit}>
              <TextField
                InputLabelProps={{
                  className: 'input-label',
                }}
                className="text-field"
                InputProps={{ disableunderline: true }} // Corrigido o aviso do DOM
                type="text"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                label="Seu e-mail"
                placeholder="Seu e-mail"
                variant="outlined"
              />
              <TextField
                className="text-field"
                InputProps={{
                  disableunderline: true, // Corrigido o aviso do DOM
                  endAdornment: (
                    <InputAdornment position="end" className="eye">
                      <IconButton
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        className="button-eye"
                        style={{ padding: '10px' }}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                InputLabelProps={{
                  className: 'input-label',
                }}
                type={showPassword ? 'text' : 'password'}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                label="Sua senha"
                placeholder="Sua senha"
                variant="outlined"
              />
              <Button className="button" type="submit" variant="contained" color="primary">
                Entrar
              </Button>
            </form>
            <Button
              className="button"
              component={Link}
              to="/cadastro"
              type="button"
              variant="contained"
              color="primary"
            >
              Cadastrar
            </Button>
            <Link className="enviar" to="/recovery">
              <p> Esqueceu sua senha?</p>
            </Link>
          </div>
        </Form>
      </Container>
    )
  );
}
