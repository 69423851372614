import React, { useState, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import TextField from '@mui/material/TextField';
import { toast } from 'react-toastify';
import PasswordChecklist from 'react-password-checklist';
import { Button, InputAdornment, IconButton, useMediaQuery } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import axios from '../../services/axios';
import { ReactComponent as Logo } from '../../assets/img/Logo  (1).svg';
import * as actions from '../../store/modules/auth/actions';
import { Composition, Container } from './styled';

function PasswordRecoveryPage() {
  const dispatch = useDispatch();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get('token') || '';
  const history = useHistory();
  const [isTokenValid, setIsTokenValid] = useState(null);

  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [isPasswordValid, setIsPasswordValid] = useState(false);

  // Media query for responsiveness
  const isSmallScreen = useMediaQuery('(max-width: 768px)');

  useEffect(() => {
    async function checkTokenValidity() {
      try {
        const response = await axios.get(`/users/check-token?token=${token}`);
        if (response.data.isValid) {
          setIsTokenValid(true);
        } else {
          setIsTokenValid(false);
        }
      } catch (error) {
        console.error('Erro ao verificar a validade do token:', error);
        setIsTokenValid(false);
      }
    }

    checkTokenValidity();
  }, [token]);

  const handleNewPasswordChange = (e) => {
    setNewPassword(e.target.value);
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handlePasswordValidationChange = (isValid) => {
    setIsPasswordValid(isValid);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!isPasswordValid) {
      toast.error('A senha não cumpre todos os requisitos');
      return;
    }

    dispatch(actions.passwordRecoveryRequest({ history, token, newPassword }));
  };

  return (
    <Container>
      <Composition onSubmit={handleSubmit} isSmallScreen={isSmallScreen}>
        <div className="box-container">
          <Logo className="logo-box" />
          <h1>Redefinição de Senha</h1>
          {isTokenValid === false ? (
            <p>Link inválido. Por favor, solicite uma nova redefinição.</p>
          ) : (
            <>
              <TextField
                InputLabelProps={{
                  className: 'input-label',
                }}
                className="text-field"
                variant="outlined"
                id="standard-adornment-password"
                type={showPassword ? 'text' : 'password'}
                value={newPassword}
                onChange={handleNewPasswordChange}
                label="Nova Senha"
                InputProps={{
                  disableUnderline: true,
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        className="button-eye"
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />

              <TextField
                className="text-field"
                InputLabelProps={{
                  className: 'input-label',
                }}
                variant="outlined"
                id="standard-adornment-password-again"
                type={showConfirmPassword ? 'text' : 'password'}
                value={confirmPassword}
                onChange={handleConfirmPasswordChange}
                label="Confirmar Senha"
                InputProps={{
                  disableUnderline: true,
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        className="button-eye"
                        aria-label="toggle password visibility"
                        onClick={handleClickShowConfirmPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />

              <PasswordChecklist
                className="password-checklist"
                rules={['minLength', 'specialChar', 'number', 'capital', 'match']}
                minLength={8}
                value={newPassword}
                valueAgain={confirmPassword}
                onChange={handlePasswordValidationChange}
                messages={{
                  minLength: 'A senha tem mais de 8 caracteres.',
                  specialChar: 'A senha tem caracteres especiais.',
                  number: 'A senha tem número.',
                  capital: 'A senha tem letra maiúscula.',
                  match: 'As senhas coincidem.',
                }}
              />

              <Button type="submit" variant="contained" color="primary" fullWidth>
                Redefinir Senha
              </Button>
            </>
          )}
        </div>
      </Composition>
    </Container>
  );
}

export default PasswordRecoveryPage;
