import styled from 'styled-components';
import breakpoints from '../../styles/breakpoints';

export const Container = styled.div`
  max-width: 600px;
  margin: 10% auto;
  padding: 20px;

  @media ${breakpoints.xl} {
    margin-top: 10%;
  }

  @media ${breakpoints.lg} {
    margin-top: 10%;
  }

  @media ${breakpoints.md} {
    margin-top: 20%;
  }

  @media ${breakpoints.sm} {
    margin-top: 25%;
  }

  @media ${breakpoints.xm} {
    margin-top: 30%;
  }
`;

export const Title = styled.h1`
  font-size: 25px;
  font-family: 'Poppins', sans-serif;
  text-align: center;
  margin-bottom: 20px;

  @media ${breakpoints.sm} {
    font-size: 20px;
  }
`;

export const FormRow = styled.div`
  width: 100%;
  display: flex;
  font-family: 'Poppins', sans-serif;
  flex-direction: column; /* Alterado para coluna */
  gap: 15px;

  & > * {
    margin-bottom: 15px; /* Margem entre os inputs e botão */
  }

  button {
    border-radius: 35px;
    text-transform: none;
    font-weight: bold;
    font-family: 'Poppins', sans-serif;
    background-color: #0a798d;

    &:hover {
      background-color: #00465a;
    }
  }

  .buttonEnv {
    height: 30px;
    border-radius: 35px;
    background-color: #0a798d;

    &:hover {
      background-color: #00465a;
    }
  }
`;
